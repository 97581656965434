define('@fortawesome/pro-solid-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   * Copyright 2024 Fonticons, Inc.
   */
  const faMicrowave = {
    prefix: 'fas',
    iconName: 'microwave',
    icon: [576, 512, [], "e01b", "M0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 288c0 35.3-28.7 64-64 64c0 17.7-14.3 32-32 32s-32-14.3-32-32l-320 0c0 17.7-14.3 32-32 32s-32-14.3-32-32c-35.3 0-64-28.7-64-64L0 96zm96 56l0 176c0 13.3 10.7 24 24 24l272 0c13.3 0 24-10.7 24-24l0-176c0-13.3-10.7-24-24-24l-272 0c-13.3 0-24 10.7-24 24zm400-40c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"]
  };
  const faStairs = {
    prefix: 'fas',
    iconName: 'stairs',
    icon: [576, 512, [], "e289", "M384 64c0-17.7 14.3-32 32-32l128 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-96 0 0 96c0 17.7-14.3 32-32 32l-96 0 0 96c0 17.7-14.3 32-32 32l-96 0 0 96c0 17.7-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32s14.3-32 32-32l96 0 0-96c0-17.7 14.3-32 32-32l96 0 0-96c0-17.7 14.3-32 32-32l96 0 0-96z"]
  };
  const faRefrigerator = {
    prefix: 'fas',
    iconName: 'refrigerator',
    icon: [384, 512, [], "e026", "M96 0C43 0 0 43 0 96l0 64 288 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 64 0 0-64c0-53-43-96-96-96L96 0zM384 192l-64 0 0 176c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-176L0 192 0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-256z"]
  };
  const faPhoneRotary = {
    prefix: 'fas',
    iconName: 'phone-rotary',
    icon: [512, 512, [9742], "f8d3", "M0 141.3c0-8.5 3.4-16.6 9.9-22.1C38.2 95.4 125.9 32 256 32s217.8 63.4 246.1 87.2c6.5 5.5 9.9 13.6 9.9 22.1l0 34.7c0 17.7-14.3 32-32 32l-42.3 0c-13.1 0-24.9-8-29.7-20.1l-19.4-48.6c-2.9-7.3-8.4-13.2-15.7-16c-18.6-7-59.5-19.3-116.8-19.3s-98.2 12.3-116.8 19.3c-7.3 2.8-12.8 8.7-15.7 16L104 187.9C99.2 200 87.4 208 74.3 208L32 208c-17.7 0-32-14.3-32-32l0-34.7zM32 432l0-31c0-11.1 2.9-22.1 8.4-31.8l101.1-177C153 172.3 174.2 160 197.1 160l117.7 0c23 0 44.2 12.3 55.6 32.2l101.1 177c5.5 9.7 8.4 20.6 8.4 31.8l0 31c0 26.5-21.5 48-48 48L80 480c-26.5 0-48-21.5-48-48zM328 312a72 72 0 1 0 -144 0 72 72 0 1 0 144 0z"]
  };
  const faCoffeePot = {
    prefix: 'fas',
    iconName: 'coffee-pot',
    icon: [512, 512, [], "e002", "M64 128c0-17.7 14.3-32 32-32l12.8 0 34 84.9C95 215.9 64 272.3 64 336l0 8c0 55.5 34.6 99.4 55.9 121.2c9.9 10.2 23.9 14.8 38.1 14.8L418 480c14.2 0 28.1-4.7 38.1-14.8C477.4 443.4 512 399.5 512 344l0-8c0-63.7-31-120.1-78.8-155.1L478.1 68.8c1.3-3.2 1.9-6.6 1.9-10C480 44 468 32 453.2 32L192 32l-69.2 0L96 32C43 32 0 75 0 128l0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64zm65 192c5.2-41.4 30.1-76.6 65-96l188 0c34.9 19.4 59.8 54.6 65 96l-318 0z"]
  };
  const faGamepadModern = {
    prefix: 'fas',
    iconName: 'gamepad-modern',
    icon: [640, 512, [127918, 63676, "gamepad-alt"], "e5a2", "M0 369.4l0 2.8C0 431.7 48.3 480 107.8 480c49.5 0 92.6-33.7 104.6-81.7L216 384l208 0 3.6 14.3c12 48 55.1 81.7 104.6 81.7C591.7 480 640 431.7 640 372.2l0-2.8c0-6.3-.5-12.5-1.6-18.7L605.7 159.8c-8.6-50.2-40.9-93.2-90.3-105.5C469.5 42.9 403.8 32 320 32s-149.5 10.9-195.3 22.3C75.2 66.6 42.9 109.6 34.3 159.8L1.6 350.7C.5 356.9 0 363.1 0 369.4zM432 232a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm24-56a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zm-240-8l0 32 32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0 0 32c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-32-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l32 0 0-32c0-13.3 10.7-24 24-24s24 10.7 24 24z"]
  };
  const faWashingMachine = {
    prefix: 'fas',
    iconName: 'washing-machine',
    icon: [448, 512, ["washer"], "f898", "M0 64C0 28.7 28.7 0 64 0L384 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM368 304A144 144 0 1 0 80 304a144 144 0 1 0 288 0zM88 112a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM208 88a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm16 328c-61.9 0-112-50.1-112-112c0-16.5 3.6-32.2 10-46.3c7.4-1.1 14.8-1.7 22-1.7c32 0 56 16 80 32s48 32 80 32c10.9 0 21.4-.9 31.2-2.5C328.5 373 281.3 416 224 416z"]
  };
  const faArrowDownArrowUp = {
    prefix: 'fas',
    iconName: 'arrow-down-arrow-up',
    icon: [576, 512, ["sort-alt"], "f883", "M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-96-96c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L128 370.7 128 64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 306.7 41.4-41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-96 96zm352-333.3c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L448 141.3 448 448c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-306.7-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0l96 96z"]
  };
  const faOven = {
    prefix: 'fas',
    iconName: 'oven',
    icon: [448, 512, [], "e01d", "M0 96l0 64 448 0 0-64c0-53-43-96-96-96L96 0C43 0 0 43 0 96zM56 80a24 24 0 1 1 48 0A24 24 0 1 1 56 80zM176 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm72 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm16 200l0 192L64 448l0-192 320 0zM64 192L0 192l0 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-192 0-64-64 0L64 192zm48 96c-8.8 0-16 7.2-16 16s7.2 16 16 16l224 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-224 0z"]
  };

  exports.faArrowDownArrowUp = faArrowDownArrowUp;
  exports.faCoffeePot = faCoffeePot;
  exports.faGamepadModern = faGamepadModern;
  exports.faMicrowave = faMicrowave;
  exports.faOven = faOven;
  exports.faPhoneRotary = faPhoneRotary;
  exports.faRefrigerator = faRefrigerator;
  exports.faStairs = faStairs;
  exports.faWashingMachine = faWashingMachine;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
